import { useEthers } from "@usedapp/core"


import {useState} from 'react'
import useERC721Token from "../hooks/useERC721Token"
import styled from "styled-components"
import useBurnFunction from "../hooks/useERC721BurnFunction"
import useTransferFromFunction from "../hooks/useERC721TransferFromFunction"
import { BorderRad, Colors } from "../global/styles"

type ERC721TokenProps = {
    contractAddress: string
    tokenId: number
}

const ERC721Token = ({ contractAddress, tokenId }: ERC721TokenProps) => {
    const {account} = useEthers()
    const { metadata } = useERC721Token(contractAddress, tokenId);

    const { send: sendBurn } = useBurnFunction(contractAddress)
    const { send: sendTransferFrom } = useTransferFromFunction(contractAddress)

    const [transferAddress, setTransferAddress] = useState('')

    const burn = async () => {
        await sendBurn(tokenId)
    }

    const transferToken = async () => {
        if(account && transferAddress && !!tokenId) {
            sendTransferFrom(account, transferAddress, tokenId)
        } else {
            console.log('Cannot transfer')
        }
    }

    return tokenId ? (
        <TokenBox id={tokenId.toString()} key={tokenId} >
                <h2>{metadata.name}</h2>
                <TokenImageContainer >
                    <TokenImage src={metadata.image} />
                </TokenImageContainer>
                <p>{metadata.description}</p>
                <div>
                    <p>Token ID: {tokenId}</p>
                    {/* <pre>{JSON.stringify(metadata, null, 2)}</pre> */}
                    <button onClick={() => burn()}>Burn</button>
                    <form onSubmit={(e) => {e.preventDefault(); transferToken()}}>
                        <input
                        id="transferAddress"
                        type="text"
                        value={transferAddress}
                        onChange={e => setTransferAddress(e.target.value)}
                         />
                         <button type="submit">Transfer</button>
                    </form>
                </div>
        </TokenBox>
    ) : <></>

}

const TokenBox = styled.div`
border: 2;
border: ${Colors.Gray['300']} 1px solid;
border-radius: ${BorderRad.m};

text-align: center;
width: 275px;
`

const TokenImageContainer = styled.div`
    height: 250px;
    overflow: hidden;
    width: 250px;
    margin: 10px 10px 0 10px;
`

const TokenImage = styled.img`
    // background-size: contain;
    // background-repeat: no-repeat;
    width: 100%;
    height: 100%
    // padding-top: 100%;
    border: 0;
    border: ${Colors.Gray['300']} 1px solid;
    border-radius: ${BorderRad.m};
    overflow: hidden;
`;


export default ERC721Token;