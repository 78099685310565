import { Interface } from "@ethersproject/abi";
import { useContractFunction, useEthers } from "@usedapp/core";
import { ethers } from "ethers";


const MintableAbi =
    [{
        "inputs":
            [{ "internalType": "address", "name": "to", "type": "address" },
            { "internalType": "string", "name": "tokenURI", "type": "string" }],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    ]


const MintableInterface = new Interface(MintableAbi)

const useMintFunction = (address: string) => {
    const { library } = useEthers();

    return useContractFunction(new ethers.Contract(address, MintableInterface, library), "mint");
}

export default useMintFunction