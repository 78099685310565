import { MainContent, Container, Section, SectionRow } from "../components/base/base"
import { Title } from "../components/base/Title"
import ERC721Info from "../components/ERC721Info"
import { MintErc721Form } from "../components/ERC721Mint"

interface ERC20ManagerParams {
    contractAddress: string
}

export function ERC721Manager({ contractAddress }: ERC20ManagerParams) {

    return (
        <MainContent>
            <Container>
                <Section>
                    <SectionRow>
                        <Title>Leo Love Token Manager</Title>
                    </SectionRow>
                </Section>
                <ERC721Info contractAddress={contractAddress} />
                <Section>
                        <MintErc721Form contractAddress={contractAddress} />
                </Section>
            </Container>
        </MainContent>
    )
}