import { Interface } from "@ethersproject/abi";
import { useContractCall } from "@usedapp/core";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";

import { BigNumberish } from "ethers"

const SymbolAbi =
[
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
        {
            "internalType": "string",
            "name": "",
            "type": "string"
        }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true
}
]


const SymbolInterface = new Interface(SymbolAbi)

export function useSymbol(tokenAddress: string | Falsy): BigNumberish {    
  const symbol = useContractCall({
    abi: SymbolInterface,
    method: 'symbol',
    address: tokenAddress || '',
    args: [],
  })

  return symbol?.[0] || ''
}


export default useSymbol