import styled from 'styled-components'
import { Colors, Shadows, Sizes, Transitions } from '../../global/styles'
import { AccountButton } from '../account/AccountButton'
import { HeaderContainer, RedHeart } from '../base/base'


export function TopBar() {
  return (
    // <AppBar
    //   position='sticky'
    //   elevation={0}
    //   sx={{
    //     justifyContent: 'center'
    //   }}
    //   // color="default"
    // >
    //   <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
    //     <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
    //       <MenuLink href="/" color='inherit'>
    //       Leonard <RedHeart size="1x" /> Love
    //       </MenuLink>
    //     </Typography>
    //     <nav>
    //       <MenuLink href="/manage" >
    //         <Button color="inherit">
    //         {' '}
    //         Manage{' '}
    //         </Button>
           
    //       </MenuLink>
    //       <MenuLink href="/love">
    //         {' '}
    //         Love <RedHeart />{' '}
    //       </MenuLink>
    //       <Link
    //           variant="button"
    //           color="text.primary"
    //           href="#"
    //           sx={{ my: 1, mx: 1.5 }}
    //         >
    //           Features
    //         </Link>
    //       {/*
    //         <Link
    //           variant="button"
    //           color="text.primary"
    //           href="#"
    //           sx={{ my: 1, mx: 1.5 }}
    //         >
    //           Enterprise
    //         </Link>
    //         <Link
    //           variant="button"
    //           color="text.primary"
    //           href="#"
    //           sx={{ my: 1, mx: 1.5 }}
    //         >
    //           Support
    //         </Link> */}
    //     </nav>
    //     <AccountButton />
    //   </Toolbar>
    // </AppBar>

    /* <AppBar
    position="static"
    color="default"
    elevation={0}
    sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    > */


    <Header>
      <HeaderContainer>
        <HeaderNav>
          <ToMain href="/">
              <span>Leonard <RedHeart size="1x"/> Love</span>
          </ToMain>
          {/* <HeaderNavLinks>
            <HeaderLink to="/manage">
              {' '}
              Manage{' '}
            </HeaderLink>
            <HeaderLink  to="/love">
              {' '}
              Love <RedHeart />{' '}
            </HeaderLink>
          </HeaderNavLinks> */}
          <AccountButton />
        </HeaderNav>
      </HeaderContainer>
    </Header>
  )
}

const Header = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  width: 100%;
  height: ${Sizes.headerHeight};
  background-color: ${Colors.White};
  box-shadow: ${Shadows.main};
  z-index: 100;
`

const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  justify-content: space-between;
`

const ToMain = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  transition: ${Transitions.all};

  &:hover {
    color: ${Colors.Yellow[500]};
  }
`

// const HeaderNavLinks = styled.div`
//   display: grid;
//   grid-auto-flow: column;
//   align-items: center;
//   grid-column-gap: 20px;
//   align-items: center;
//   height: 100%;
// `

// const HeaderLink = styled(NavLink)`
//   display: flex;
//   position: relative;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   padding: 10px;
//   font-size: 12px;
//   line-height: 24px;
//   font-weight: 700;
//   letter-spacing: 0.1em;
//   text-transform: uppercase;
//   transition: ${Transitions.all};
//   white-space: nowrap;

//   &:after {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     width: calc(100% - 20px);
//     height: 2px;
//     background-color: ${Colors.Yellow[500]};
//     transform: scaleX(0);
//     transform-origin: 50% 50%;
//     transition: ${Transitions.all};
//   }

//   &:hover {
//     color: ${Colors.Yellow[500]};

//     &:after {
//       transform: scaleX(1);
//     }
//   }
//   &.active {
//     &:after {
//       transform: scaleX(1);
//     }
//   }
// `
