import { Interface } from "@ethersproject/abi";
import { useContractCall } from "@usedapp/core";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";
import { useEffect, useState } from "react"


import ERC721Abi from "../abi/LeonardLoveToken.abi.json"

const abi_string = JSON.stringify(ERC721Abi).toString()
const ERC721Interface = new Interface(abi_string)


interface IERC721 {
    tokenAddress: string | Falsy
    tokenId: number
    uri: string,
    metadata: ERC721Metadata
  }

interface ERC721Metadata {
  name: string
  description: string
  image: string
}

export function useERC721Token(tokenAddress: string | Falsy, tokenId: number): IERC721 {
  const [metadata, setMetadata] = useState(JSON.parse('{}'))

  let uri = useContractCall({
    abi: ERC721Interface,
    method: 'tokenURI',
    address: tokenAddress || '',
    args: [tokenId],
  })
    
  useEffect(() => {
    if(uri) {
      fetch(uri[0])
      .then(response => response.json())
      .then(json => setMetadata(json))
      .catch(e => console.log(e))
    }
  }, [uri])

  return {
    tokenAddress: tokenAddress,
    tokenId: tokenId,
    uri: uri?.[0],
    metadata: metadata
  }
}


export default useERC721Token