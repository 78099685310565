import { useState } from "react";
import styled from "styled-components";
import useSymbol from "../hooks/useSymbol";
import { Button } from "./base/Button";

declare let window: any;

interface AddToMetamaskProps {
    account?: string
    address: string,
    image?: string,
    decimals?: number
}
const AddToMetamask = ({address, image, decimals = 0}: AddToMetamaskProps) => {
    const { ethereum } = window;
    const symbol = useSymbol(address);
    const [added, setAdded] = useState(false);

    const addToMetamask = async () => {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await ethereum.request({
            method: 'wallet_watchAsset',
            params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
                address: address, // The address that the token is at.
                symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
                image: image, // A string url of the token logo
                decimals: decimals
            },
            },
        })
        //   .catch(console.error)
    
        if (wasAdded) {
            setAdded(true)
            console.log('Thanks for your interest!');
        } else {
            setAdded(false)
            console.log('Your loss!');
        }
    }

    return symbol ? 
        added ? 
        <p>Thanks for adding {symbol}</p> : 
        <AddButton onClick={() => addToMetamask()}>
            Add {symbol} to Metamask
            </AddButton>
    : <span>"Loading Token"</span>
}

const AddButton = styled(Button)`
    padding-left: 5px;
    padding-right: 5px;
`

export default AddToMetamask;