
import { Interface } from "@ethersproject/abi";
import { useContractFunction, useEthers } from "@usedapp/core";
import { ethers } from "ethers";


const BurnableAbi =
[{
    "inputs": [
        {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }
    ],
    "name": "burn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}]


const BurnableInterface = new Interface(BurnableAbi)

const useBurnFunction = (address: string) => {
    const { library } = useEthers();

    return useContractFunction(new ethers.Contract(address, BurnableInterface, library), "burn");
}

export default useBurnFunction