import './App.css';
import { DAppProvider } from '@usedapp/core';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import { Page } from './components/base/base';
import { TopBar } from './components/TopBar/TopBar';

import { GlobalStyle } from './global/GlobalStyle';


import { Page } from './components/base/base';
import { ERC721Manager } from './pages/ERC721Manager';


const dAppProviderConfig = {
  // readOnlyChainId: Mainnet.chainId,
  // readOnlyUrls: {
  //   [Mainnet.chainId]: 'https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934',
  // },
  multicallAddresses: {
    1337: '0x1ec6a4cB9a8e324D8B5eFfbB96C4954e89f47349'
  }
}

// const leoContractConfig = {
//   // coinContractAddress: {
//   //   1337: '0x7504E1b700671A48a503761Da328835DD9715534'
//   // },
//   '1337': {
//     coinContractAddress: '0x7504E1b700671A48a503761Da328835DD9715534',
//     tokenContractAddress: '0xb921723CFD428a13DbCF47f4d4521f895f1d4d95'
//   },
//   '4': {
//     tokenContractAddress: '0x58012A4E881138ed764208Ec488B8973CCa2e4D9'
//   }
// }

// const leoCoinContractAddress = '0x7504E1b700671A48a503761Da328835DD9715534'
// const leoTokenContractAddress = '0xb921723CFD428a13DbCF47f4d4521f895f1d4d95'

function App() {
  return (
    <DAppProvider config={dAppProviderConfig}>
      <GlobalStyle />

      <Page>
        <BrowserRouter>
          <TopBar />
          <Routes>
            <Route path="/" >
              {/* <Route path="coin" element={chainId ? <ERC20Manager contractAddress={''} /> : <></>} /> */}
              <Route index element={<ERC721Manager contractAddress={'0x58012A4E881138ed764208Ec488B8973CCa2e4D9'} />} />
              {/* <Route index element={<Love contractAddress={leoCoinContractAddress} />} />
              <Route index element={<Love contractAddress={leoCoinContractAddress} />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
        {/* <NotificationsList /> */}
      </Page>
    </DAppProvider>
  );
}


export default App;
