import { useEthers, useTokenBalance } from "@usedapp/core"

import { formatUnits } from 'ethers/lib/utils'
import { BigNumberish } from "ethers"
import { ContentBlock, ContentRow, Section, TitleRow, CellTitle } from "./base/base"
import { ethers } from 'ethers'
import useERC721 from "../hooks/useERC721"
import useOwnedERC721Tokens from "../hooks/useERC721Tokens"
import ERC721Token from "./ERC721Token"
import AddToMetamask from "./AddToMetamask"

import { Reel } from '@bedrock-layout/reel'

type ERC20InfoProps = {
    contractAddress: string
}

function formatBalance(balance: BigNumberish | undefined, decimals: BigNumberish | undefined) {
    return (balance && decimals) ? formatUnits(balance, decimals) : '0'
}

const ERC721Info = ({ contractAddress }: ERC20InfoProps) => {
    const { account, chainId } = useEthers()
    const tokenBalance = useTokenBalance(contractAddress, account)
    const erc721 = useERC721(contractAddress);
    const { tokenIds } = useOwnedERC721Tokens(contractAddress, account);

    if (erc721) {

        const tokens = tokenIds.map(
            id => {
                return id ?
                    <ERC721Token key={id} contractAddress={contractAddress} tokenId={id} />
                    : <></>
            }
        );

        return (
            <>
                <Section>
                    <ContentBlock>
                        <TitleRow>
                            <CellTitle>Info</CellTitle>
                        </TitleRow>
                        <ContentRow>
                            <p>Chain {chainId}</p>
                            <p>Address: {contractAddress}</p>
                            <p>Name: {erc721.name}</p>
                            <p>Symbol: {erc721.symbol}</p>
                            <div>
                                <p>Total Supply: {formatBalance(erc721.totalSupply, ethers.constants.Zero)} {erc721.symbol}</p>
                            </div>
                            <div>
                                <p>Balance: {formatBalance(tokenBalance, ethers.constants.Zero)} {erc721.symbol}</p>
                            </div>
                        </ContentRow>
                        <ContentRow>
                            <AddToMetamask address={contractAddress} image={"https://gateway.pinata.cloud/ipfs/QmZXXi6aq6fC947v3Z5P7gzABL57qT23YK4SfkcpLddyh4/0.jpeg"} />
                        </ContentRow>
                    </ContentBlock>
                </Section>
                <Section>
                    <ContentBlock>
                        <TitleRow>
                            <CellTitle>Wallet</CellTitle>
                        </TitleRow>
                        <ContentRow>
                            <Reel gutter="lg">
                                {tokens}
                            </Reel>
                        </ContentRow>
                    </ContentBlock>
                </Section>
            </>
        )
    } else {
        return (
            <h1>Loading</h1>
        )
    }
}



export default ERC721Info;