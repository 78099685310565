import { Interface } from "@ethersproject/abi";
import { ContractCall, useContractCalls, useEthers } from "@usedapp/core";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";
import { useEffect, useState } from "react"

import { BigNumberish } from "ethers"

import ERC721Abi from "../abi/LeonardLoveToken.abi.json"

const abi_string = JSON.stringify(ERC721Abi).toString()
const ERC721Interface = new Interface(abi_string)


interface IERC721 {
    name: string
    symbol: string
    decimals?: number
    totalSupply?: BigNumberish
    balance?: number
    deployed?: boolean
  }

export function useERC721(tokenAddress: string | Falsy): IERC721 {
  const [deployed, setDeployed] = useState<boolean>()

  const partialCall = {
    abi: ERC721Interface,
    address: tokenAddress || '',
    args: [],
  }
  const args = ['name', 'symbol', 
  'totalSupply'
].map((method): ContractCall => ({ ...partialCall, method }))
  let [name, symbol, 
    totalSupply
  ] = useContractCalls(args)
  
  const {library} = useEthers()
  
  useEffect(() => {
    if(!tokenAddress || !library)
        return;
    
    library.getCode(tokenAddress)
        .then(code => setDeployed((code !== "0x")))
        .catch(error => console.log(error))
  }, [library, tokenAddress])

  return {
    name: name?.[0] ?? '',
    symbol: symbol?.[0] ?? '',
    totalSupply: totalSupply?.[0],
    deployed: deployed
  }
}


export default useERC721