
import { Interface } from "@ethersproject/abi";
import { useContractFunction, useEthers } from "@usedapp/core";
import { ethers } from "ethers";


const TranferFromAbi =
[{
    "inputs": [
        {
            "internalType": "address",
            "name": "from",
            "type": "address"
        },
        {
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        }
    ],
    "name": "transferFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}]


const TranferFromInterface = new Interface(TranferFromAbi)

const useBurnFunction = (address: string) => {
    const { library } = useEthers();

    return useContractFunction(new ethers.Contract(address, TranferFromInterface, library), "transferFrom");
}

export default useBurnFunction