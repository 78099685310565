import { useEffect, useState } from 'react'
import { ContentBlock, TitleRow, CellTitle } from './base/base'
import { Colors, BorderRad, Transitions } from '../global/styles'
import styled from 'styled-components'
import { useEthers } from '@usedapp/core'
import { Button } from './base/Button'
import useMintFunction from '../hooks/useERC721MintFunction'

import { create } from "ipfs-http-client";
import { ImportCandidate } from 'ipfs-core-types/src/utils'

const client = create({ url: "https://ipfs.infura.io:5001/api/v0" });

interface InputProps {
  tokenAddress: string
}

const InputComponent = ({ tokenAddress }: InputProps) => {
  const { account } = useEthers()

  const [address, setAddress] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState<File>()
  const [disabled, setDisabled] = useState(false)

  const { send: sendMint, state } = useMintFunction(tokenAddress);

  const ipfsUpload = async (file: ImportCandidate) => {
    try {
      const created = await client.add(file);
      const url = `https://ipfs.infura.io/ipfs/${created.path}`;
      console.log(url)
      return url;
    } catch (error) {
      console.log(error);
      return ''
    }
  };

  const handleClick = async () => {
    setDisabled(true)

    if (image) {
      const imageUri = await ipfsUpload(image)
      const metadata = {
        name: name,
        description: description,
        image: imageUri
      }
      const metadataUri = await ipfsUpload(JSON.stringify(metadata))
      const result = await sendMint(address, metadataUri)
      console.log(result)
      setDisabled(false)
    }

    setDisabled(false)
  }

  useEffect(() => {
    if (account) {
      setAddress(account)
    }
  }, [account])

  useEffect(() => {
    if (state.status !== 'Mining' && state.status !== 'None') {
      setDisabled(false)
      setAddress('')
      setName('')
      setDescription('')
      setImage(undefined)
    }
  }, [state])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Label htmlFor="name">Name</Label>

        <Label htmlFor="image">Image</Label>
      </Row>
      <Row>
        <Input
          id="name"
          type="text"
          value={name}
          placeholder='Name'
          onChange={e => setName(e.target.value)}
          disabled={disabled}
        />
        <Input
          id="image"
          type="file"
          onChange={e => setImage(e.target.files?.[0] || undefined)}
          disabled={disabled}
        />
      </Row>

      <Row>
        <Label htmlFor="description">Description</Label>
      </Row>
      <Row>
        <Input
          id="description"
          value={description}
          placeholder='Description'
          onChange={e => setDescription(e.target.value)}
          disabled={disabled}
          style={{width: '80%'}}
        />
      </Row>
      <Row>
        <SmallButton disabled={!account || disabled} onClick={handleClick}>
          Mint
        </SmallButton>
      </Row>
    </div>
  )
}

interface MintErc20FormProps {
  contractAddress: string
}

export const MintErc721Form = ({ contractAddress }: MintErc20FormProps) => {
  return (
    <ContentBlock style={{ padding: 0 }}>
      <TitleRow>
        <CellTitle>Mint</CellTitle>
      </TitleRow>
      <InputComponent tokenAddress={contractAddress} />
    </ContentBlock>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 24px 0;
  width: 100%;
`

const Label = styled.label`
  font-weight: 700;
  cursor: pointer;
  transition: ${Transitions.all};

  &:hover,
  &:focus-within {
    color: ${Colors.Yellow[500]};
  }
`

const Input = styled.input`
  height: 100%;
  min-height: 2em;
  min-width: 120px;
  padding: 0 0 0 24px;
  border: 0;
   border: ${Colors.Gray['300']} 1px solid;
  border-radius: ${BorderRad.m};
  -moz-appearance: textfield;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }
`

// const AddressInput = styled(Input)`
//   width: 401px;
//   padding: 0 0 0 38px;
// `

// const InputRow = styled.div`
//   height: 44px;
//   display: flex;
//   margin: 0 auto;
//   color: ${Colors.Gray['600']};
//   align-items: center;
//   overflow: hidden;
//   transition: ${Transitions.all};

//   &:hover,
//   &:focus-within {
//     border-color: ${Colors.Black[900]};
//   }
// `

// const FormTicker = styled.div`
//   padding: 0 8px;
// `

const SmallButton = styled(Button)`
  display: flex;
  justify-content: center;
  min-width: 95px;
  height: 100%;
  padding: 8px 24px;

  &:disabled {
    color: ${Colors.Gray['600']};
    cursor: unset;
  }

  &:disabled:hover,
  &:disabled:focus {
    background-color: unset;
    color: unset;
  }
`
