import { Interface } from "@ethersproject/abi";
import { ContractCall, useContractCall, useContractCalls } from "@usedapp/core";
import { Falsy } from "@usedapp/core/dist/esm/src/model/types";
import { useEffect, useState } from "react"


import ERC721Abi from "../abi/LeonardLoveToken.abi.json"

const abi_string = JSON.stringify(ERC721Abi).toString()
const ERC721Interface = new Interface(abi_string)


interface IERC721 {
    tokenAddress: string | Falsy
    tokenIds: number[]
    balance: number | undefined
  }

export function useOwnedERC721Tokens(tokenAddress: string | Falsy, account: string | Falsy): IERC721 {
  const [tokenIds, setTokenIds] = useState<number[]>([])
  const [calls, setCalls] = useState<ContractCall[]>()

  let balance = useContractCall({
    abi: ERC721Interface,
    method: 'balanceOf',
    address: tokenAddress || '',
    args: [account],
  })

  const tokenIdCallResults = useContractCalls(calls ?? []);

  useEffect(() => {
    if(balance) {
      const partialCall = {
        abi: ERC721Interface,
        method: 'tokenOfOwnerByIndex',
        address: tokenAddress || '',
        args: [],
      }
      const calls:ContractCall[] = Array(balance[0].toNumber()).fill(0).map((_, i): ContractCall => 
        ({ ...partialCall, args: [account, i]})
      )

      setCalls(calls)
    }
  }, [account, tokenAddress, balance])

  useEffect(() => {
    const newTokenIds = tokenIdCallResults.map(result => result?.[0].toNumber())
    setTokenIds(newTokenIds)
  }, [tokenIdCallResults])

  return {
    tokenAddress: tokenAddress,
    balance: balance?.[0].toNumber(),
    tokenIds: tokenIds
  }
}


export default useOwnedERC721Tokens